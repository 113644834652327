<template>
  <section>
    <div class="row items-stretch">
      <q-item
        :focused="checkoutStore.isPickup == false"
        class="col-12 col-sm-6"
        tag="label"

        clickable
      >
        <q-item-section
          :avatar="$q.screen.gt.md"
          side
        >
          <q-radio
            v-model="checkoutStore.isPickup"
            :val="false"
            :dense="$q.screen.lt.lg"
            class="col-12 col-sm-6"
          />
        </q-item-section>
        <q-item-section>
          <q-item-label class="row q-gutter-x-xs">
            <span class="text-weight-bold">
              Delivery
            </span>
          </q-item-label>
          <q-item-label>
            A total of {{ pluralize(orders.length, 'shipment') }}
            will be created from your cart.
          </q-item-label>
        </q-item-section>
        <q-item-section side>
          <q-btn
            :icon="mdiTruck"
            disable
            round
          />
        </q-item-section>
      </q-item>

      <q-item
        :focused="checkoutStore.isPickup == true"
        class="col-12 col-sm-6"
        tag="label"
        clickable
      >
        <q-item-section
          :avatar="$q.screen.gt.md"
          side
        >
          <q-radio
            v-model="checkoutStore.isPickup"
            :val="true"
            :dense="$q.screen.lt.lg"
            class="col-12 col-sm-6"
          />
        </q-item-section>
        <q-item-section>
          <q-item-label class="row q-gutter-x-xs">
            <span class="text-weight-bold">
              Pick Up
            </span>
            <span class="text-weight-medium">
              @ {{ location.name }}
            </span>
          </q-item-label>
          <q-item-label>
            {{
              concat(
                ', ',
                concat(', ', location.street, location.street2),
                location.city,
                location.state,
                location.postcode,
              )
            }}
          </q-item-label>
          <q-item-label>
            {{ phone(location.phoneNumber) }}
          </q-item-label>
        </q-item-section>
        <q-item-section side>
          <q-btn
            :icon="mdiMapMarker"
            round
            @click.stop="
              navigateTo(locationUrl(location.id), {
                external: true,
                open: { target: '_blank' },
              })
            "
          />
        </q-item-section>
      </q-item>
    </div>

    <q-stepper-navigation>
      <q-btn
        :disable="!canContinue || quoteStore.pending"
        color="primary"
        label="Continue"
        square
        @click="checkoutStore.goToNext()"
      />
    </q-stepper-navigation>
  </section>
</template>

<script lang="ts" setup>
import { mdiMapMarker, mdiTruck } from '@quasar/extras/mdi-v7';

const checkoutStore = useCheckoutStore();
const quoteStore = useQuoteStore();
const { concat, phone, pluralize } = useFormatting();
const { locationUrl } = useBusiness();

const location = computed(() => checkoutStore.pickupLocation);

const orders = computed(() => quoteStore.data?.orders ?? []);

const canContinue = computed(() => !quoteStore.error);
</script>
